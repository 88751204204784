import React from 'react'

import Footer from './footer'
import Header from '../components/header'
import SEO from '../components/seo'

import classes from '../styles/wrapper.module.sass'


const Wrapper = ({children }) => {

    //const {isAuthenticated} = useMsal();
    return (

        // TODO fix position
        <div>
            <SEO/>
            <main className={classes.root}>
                {/*<ThemeChanger/>*/}
                <Header></Header>
                <div className={classes.content}>
                    { children }
                </div>
                <Footer></Footer>
            </main>
       </div>
    )

}

export default Wrapper


