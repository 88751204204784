import React from "react";
import { useStaticQuery, graphql } from 'gatsby'

import { useMsal } from '../utility/auth/msalContext';
import { loginRequest } from "./../utility/auth/msalConfig";
import classes from '../styles/login.module.sass'

function Login() {
  const { loading, isAuthenticated, login, logout, token } = useMsal();

    const data = useStaticQuery(graphql`
    query LoginQuery {
      site {
        siteMetadata {
          i18n {
            buttons {
              login
              logout
            }
            progress {
              loading
            }
          }
        }
      }
    }
  `);
  const { i18n } = data.site.siteMetadata;

  if (loading){
      return <div>{i18n.progress.loading} ...</div>
  }

  return (    
    <div>
      {(!isAuthenticated || !token) && (
              <button className={classes.button} small color="primary" onClick={() => login(loginRequest, "loginRedirect")}>{i18n.buttons.login}</button>
      )}
      {isAuthenticated && token && (
        <div className={classes.logoutcontainer}>
          {/*<h3 className={classes.name}>{user.name}</h3>*/}
          <button className={classes.button} small color="primary" onClick={() => logout()}>{i18n.buttons.logout}</button>
          
        </div>
      )}
                
    </div>
  );
}

export default Login;
