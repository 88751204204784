var graph = require('@microsoft/microsoft-graph-client');

const apiBaseUrl =  process.env.GATSBY_IDA_PROGRESS_API_URL // "https://localhost:44352"

function getAuthenticatedClient(accessToken) {
    const client = graph.Client.init({
        authProvider: (done) => {
            done(null, accessToken);
        }
    });

    return client;
}

export async function getUserDetails(accessToken) {
        
    const client = getAuthenticatedClient(accessToken);

    const user = await client
        .api('/me')
        .get();

    return user;
}

export const getUserLastCompleted = async(accessToken, courseInstanceId) => {    
    const response = await fetch(apiBaseUrl + "/api/Progress/" + courseInstanceId + "/lastCompleted", {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
}

export const getUserProgressByChapterAndSection = async(accessToken, courseInstanceId, chapterId, sectionId) => {    
    const response = await fetch(apiBaseUrl + "/api/Progress/" + courseInstanceId + "/modules/" + chapterId + "/sections/" + sectionId, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
}

export const getUserProgressByChapter = async(accessToken, courseInstanceId, chapterId) => {    
    const response = await fetch(apiBaseUrl + "/api/Progress/" + courseInstanceId + "/modules/" + chapterId, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
}

export const getUserProgress = async(accessToken, courseInstanceId) => {  
    const response = await fetch(apiBaseUrl + "/api/Progress/" + courseInstanceId + "/modules", {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
}

export const addCompletedExercise = async(accessToken, courseInstanceId, chapterId, sectionId, exerciseId, exerciseData) => {
    const data = {        
        moduleId: chapterId,
        sectionId: sectionId,
        exerciseId: exerciseId,
        exerciseData: exerciseData ? JSON.stringify(exerciseData) : "{}"
    }
    await fetch(apiBaseUrl + "/api/Progress/" + courseInstanceId, {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });    
}

export const removeCompletedExercise = async(accessToken, courseInstanceId, chapterId, sectionId, exerciseId) => {        
    await fetch(apiBaseUrl + "/api/Progress/" + courseInstanceId + "/modules/" + chapterId + "/sections/" + sectionId + "/exercises/" + exerciseId, {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        }
    });    
}

export const getResultsForCourseInstance = async(accessToken, courseInstanceId, moduleId, sectionId, exerciseId) => {  
    console.log("fetch exercise results");  
    const response = await fetch(apiBaseUrl + "/api/Progress/" + courseInstanceId + "/modules/" + moduleId + "/sections/" + sectionId + "/exercises/" + exerciseId, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
    });

    return await response.json();
}

export const getUsers = async(accessToken, courseInstanceId) => {
    console.log("fetch all users for courseInstance")  
    const response = await fetch(apiBaseUrl + "/api/Results/" + courseInstanceId + "/users", {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
    });

    return await response.json();
}

export const getUserResults = async(accessToken, courseInstanceId, userIdentity) => {
    console.log("fetch user results")  
    const response = await fetch(apiBaseUrl + "/api/Results/" + courseInstanceId + "/users/" + userIdentity, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
    });

    return await response.json();
}
