import React from "react"
import Login from '../components/login'
import HeaderBurger from '../components/header-burger'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import classes from '../styles/header.module.sass'
import { useMsal } from '../utility/auth/msalContext';

const Header = () => {
    const { user, token, isAuthenticated } = useMsal();

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const data = useStaticQuery(graphql`
      query HeaderDataQuery {
        site {
          siteMetadata {
            headerTitle
            menuData {
              title
              newTab
              link
            }
          }
        }
      }
    `);


    
    return (
        <div className={classes.nav}>


            <div className={classes.logocontainer}>
                <h1 className={classes.logo} onClick={() => navigate('/')}>
                    {data.site.siteMetadata.headerTitle}
                </h1>
            </div>
            
            
            <div className={classes.burger}>
                <HeaderBurger props={classes.nav}></HeaderBurger>
            </div>

            <div className={classes.navmenu}>
                {data.site.siteMetadata.menuData.map((item, index) => (
                    <div>
                        {!item.newTab &&
                            <div>
                                <h2 className={classes.navlink} onClick={() => navigate(item.link)} key={index}>
                                    {item.title}
                                </h2>
                            </div>
                        }
                        {item.newTab &&
                            <div>
                                <h2 className={classes.navlink} onClick={() => openInNewTab(item.link)} key={index}>
                                    {item.title}
                                </h2>
                            </div>
                        }

                    </div>

                ))}
            </div>
            
            
           <div className={classes.login}>
               { user && isAuthenticated && token && (
               <div className={classes.identification}>
                    {user.name}
                    <br></br>
                    {user.username}
                </div>
                )}
                <Login></Login>
            </div>



        </div>

    )
}

export default Header
