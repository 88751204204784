import React, { useState, useCallback } from "react"
import classes from '../styles/headerburger.module.sass'
import Login from '../components/login'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Link, useStaticQuery, graphql } from 'gatsby'

export const HeaderBurger = () => {
    const [isActive, setActive] = useState(false);
    const data = useStaticQuery(graphql`
      query MenuDataQuery {
        site {
          siteMetadata {
            menuData {
              title
              newTab
              link
            }
          }
        }
      }
    `);
    const handleClick = useCallback(() => {
        setActive((previousState) => !previousState)
    }, []);

    return (
        <div>
            <div className={classes.bars} onClick={handleClick}>
                { !isActive && <FaBars/>}
            </div>
            { isActive && <div className={classes.bars} onClick={handleClick}>
                        <FaTimes/>
                    </div>
            }
            { isActive &&
                <div className={classes.burgermenu} onClick={handleClick}>
                    <div className={classes.menucontent}>
                        {data.site.siteMetadata.menuData.map((item, index) => (
                            <Link to={item.link}>
                                <h1 className={classes.navlink} key={index}>
                                    {item.title}
                                </h1>
                            </Link>
                        ))}
                        <div className={classes.loginbutton}>
                            <Login></Login>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

export default HeaderBurger
