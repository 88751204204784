import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import classes from '../styles/footer.module.sass'


const Footer = () => {
    const data = useStaticQuery(graphql`
      query FooterQuery {
        site {
          siteMetadata {
            footer {
              universityText
              universityURL
              instituteText
              instituteURL
              email
              html
            }
          }
        }
      }
    `);
    const { footer } = data.site.siteMetadata;

    return (
        <div className={classes.footer}>
            <div className={classes.footercontent}>
                <div className={classes.footertext}>
                    <a className={classes.footertext} href={footer.universityURL}>{footer.universityText}</a><br/>
                    <a className={classes.footertext} href={footer.instituteURL}>{footer.instituteText}</a><br/>
                    { footer.email && (<a className={classes.footertext} href={'mailto:' + footer.email}>{footer.email}</a>)}
                </div>
                <div className={classes.footerpart}>
                    <p className={classes.footertext} dangerouslySetInnerHTML={{__html: footer.html}}>
                    </p>
	        </div>
            </div>
        </div>

    )
}

export default Footer
